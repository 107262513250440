import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import Vue from 'vue';

// Application
import EditCustomGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-current-plan-service';
import EditCustomGoalPlanInitialAmountService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-initial-amount-service';
import EditCustomGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-define-by-service';
import { SearchAllianzRecurringContributionsQuery } from '@/modules/my-investment/allianz/recurring-contributions/application/queries';
import UpdatePlanCustomGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-plan-custom-goal-command';

// Domain
import { CustomInvestorGoalEntity }
  from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/current-plan-entity';
import { InitialAmountEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/initial-amount-entity';
import { DefineByEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/define-by-entity';
import {
  UpdatePlanCustomGoalDto,
  InvestorGoalContributionDto,
}
  from '@/modules/flagship/custom-investor-goal/domain/dtos/update-plan-custom-goal-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class EditCustomGoalPlanDialogViewModel {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditCustomGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_INITIAL_AMOUNT_SERVICE)
  private readonly initial_amount_service!: EditCustomGoalPlanInitialAmountService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditCustomGoalPlanDefineByService;

  @Inject(TYPES.GET_ALLIANZ_RECURRING_CONTRIBUTIONS_QUERY)
  private readonly get_allianz_recurring_contributions_query!:
    SearchAllianzRecurringContributionsQuery;

  @Inject(TYPES.UPDATE_PLAN_CUSTOM_GOAL_COMMAND)
  private readonly update_plan_custom_goal_command!: UpdatePlanCustomGoalCommand;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly view: Vue;

  readonly STEPS = [
    {
      step: 0,
      component: 'EditCustomGoalCurrentPlan',
    },
    {
      step: 1,
      component: 'EditCustomGoalInitialAmount',
    },
    {
      step: 2,
      component: 'EditCustomDefineBy',
    },
    {
      step: 3,
      component: 'EditCustomGoalNewPlan',
    },
    {
      step: 5,
      component: 'EditCustomGoalSecondContribution',
    },
    {
      step: 4,
      component: 'EditCustomGoalPlanSuccess',
    },
  ];

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-custom-goal-plan.new_custom_plan';

  current_plan: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    associated_product_id: '',
    custom_goal_id: '',
    associated_product_name: '',
  }

  readonly initial_amount: InitialAmountEntity = {
    additional_amount: 0,
    there_is_more_savings: 'no',
  }

  define_by: DefineByEntity = {
    new_target_amount: 0,
    is_defined_by_contribution: false,
    by_time: {
      wants_years: false,
      months_to_reach_goal: 0,
      you_must_save: 0,
      accumulated_amount: 0,
    },
    by_contribution: {
      contribution: 0,
      months_to_reach_goal: 0,
      accumulated_amount: 0,
    },
    initial_investment_date: '',
    final_investment_date: '',
    modify_recurring_contribution_automatically: false,
    maximum_amount_exceeded: false,
    go_back_to_one_contribution: false,
  }

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  is_loading = true;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadRecurrentContributionInformation = async () => {
    if (this.current_plan.associated_product_name === 'sowos_wealth') {
      await this.get_allianz_recurring_contributions_query.execute();
    }
  }

  initialize = (custom_investor_goal: CustomInvestorGoalEntity) => {
    this.is_loading = true;
    const { investor_goal, id } = custom_investor_goal;
    this.current_plan = {
      target_amount: this.getAmountFormatted(investor_goal.accumulated_amount),
      current_amount: this.getAmountFormatted(investor_goal.active_goal_amount || 0),
      monthly_required_amount: this.getAmountFormatted(investor_goal.monthly_required_amount),
      final_investment_date: this.date_formatter.formatDate(investor_goal.final_investment_date, 'DD/MM/YYYY'),
      associated_product_id: investor_goal.associated_product_id,
      custom_goal_id: id,
      associated_product_name: investor_goal.associated_product.name,
    };
    this.setCurrentPlantInformation();
    this.loadRecurrentContributionInformation();
    this.is_loading = false;
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getInitialAmountInformation = () => (this.initial_amount_service.getInitialAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  setCurrentPlantInformation = () => {
    this.current_plan_service.setCurrentPlanInformation(this.current_plan);
  }

  setInitialAmountInformation = () => {
    this.initial_amount_service.setInitialAmountInformation(this.initial_amount);
  }

  setDefineByInformation = () => {
    this.define_by_service.setDefineByInformation(this.define_by);
  }

  clearStateInformation = () => {
    this.current_plan = {
      target_amount: '',
      current_amount: '',
      monthly_required_amount: '',
      final_investment_date: '',
      custom_goal_id: '',
      associated_product_id: '',
      associated_product_name: '',
    };
    this.setCurrentPlantInformation();
    this.setInitialAmountInformation();
    this.setDefineByInformation();
  }

  nextStep = () => {
    if (this.current_step === this.final_step) {
      this.endProcess();
      return;
    }

    if (this.current_step < this.final_step) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step === 0) {
      this.endProcess();
      this.view.$emit('closeModal');
      return;
    }

    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess = () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }

  updateNewCustomPlan = async (
    contributions: Array<InvestorGoalContributionDto>,
  ) => {
    try {
      this.is_loading = true;
      const current_plan = this.getCurrentPlanInformation();
      const initial_amount = this.getInitialAmountInformation();
      const define_by = this.getDefineByInformation();
      const amount_to_reach_goal = (define_by.is_defined_by_contribution) ? define_by
        .by_contribution.contribution : define_by.by_time.you_must_save;
      let months = (define_by.is_defined_by_contribution) ? define_by
        .by_contribution.months_to_reach_goal : define_by.by_time.months_to_reach_goal;
      if (!define_by.is_defined_by_contribution) {
        months *= (define_by.by_time.wants_years) ? 12 : 1;
      }
      let new_plan_current_amount = parseCurrencyToNumber(current_plan.current_amount);
      if (initial_amount.there_is_more_savings === 'yes') {
        new_plan_current_amount += initial_amount.additional_amount;
      }
      const update_plan_dto: UpdatePlanCustomGoalDto = {
        custom_investor_goal: current_plan.custom_goal_id,
        defined_by: (define_by.is_defined_by_contribution) ? 'contribution' : 'time',
        desired_amount: define_by.new_target_amount,
        fixed_time_adjusted: months,
        modify_recurring_contribution_automatically: define_by
          .modify_recurring_contribution_automatically,
        go_back_to_one_contribution: define_by.go_back_to_one_contribution,
        investor_goal: {
          initial_amount: new_plan_current_amount,
          accumulated_amount: (define_by.is_defined_by_contribution) ? define_by.by_contribution
            .accumulated_amount : define_by.by_time.accumulated_amount,
          monthly_required_amount: amount_to_reach_goal,
          final_investment_date: define_by.final_investment_date,
          initial_investment_date: define_by.initial_investment_date,
        },
        contributions,
      };
      await this.update_plan_custom_goal_command.execute(update_plan_dto);
      this.current_step = this.STEPS.length - 1;
      this.current_component = this.STEPS[this.current_step];
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_plan_custom_goal_command'));
    } finally {
      this.is_loading = false;
    }
  }
}
