






























import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import EditCustomGoalPlanDialogViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-custom-goal-plan/edit-custom-goal-plan-dialog-view-model';

// Domain
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';

@Component({
  name: 'EditCustomGoalPlanDialog',
  components: {
    EditCustomGoalCurrentPlan: () => import('./EditCustomGoalCurrentPlan.vue'),
    EditCustomGoalInitialAmount: () => import('./EditCustomGoalInitialAmount.vue'),
    EditCustomDefineBy: () => import('./EditCustomDefineBy.vue'),
    EditCustomGoalNewPlan: () => import('./EditCustomGoalNewPlan.vue'),
    EditCustomGoalSecondContribution: () => import('./EditCustomGoalSecondContribution.vue'),
    EditCustomGoalPlanSuccess: () => import('./EditCustomGoalPlanSuccess.vue'),
  },
})
export default class EditCustomGoalPlanDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('customInvestorGoal', { type: Object, required: true })
  custom_investor_goal!: CustomInvestorGoalEntity;

  edit_custom_plan_dialog_view_model = Vue.observable(
    new EditCustomGoalPlanDialogViewModel(this),
  );

  mounted() {
    this.edit_custom_plan_dialog_view_model.initialize(this.custom_investor_goal);
  }

  destroyed() {
    this.edit_custom_plan_dialog_view_model.clearStateInformation();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
